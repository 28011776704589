import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"

import process from "../../assets/Process.png"
import personas from "../../assets/Personas.png"
import sitemap from "../../assets/Sitemap.png"
import bibSketch from "../../assets/bib-sketch.png"
import bibWireframe from "../../assets/wireframe_snap.png"

export default function Biblioapp() {
    return (
        <Layout>
            <div className="width-container">
                <div
                    id="project-container"
                    className="single-project-container"
                >
                    <h1>BiblioApp</h1>
                    <ul className="project-header">
                        <li>
                            <span className="label">Role</span>
                            <span className="role">UX Design</span>
                        </li>
                        <li>
                            <span className="label">Year</span>
                            <span className="role">2017</span>
                        </li>
                        <li>
                            <span className="label">Team</span>
                            <span className="role">Personal</span>
                        </li>
                    </ul>
                    <div className="single-project-content">
                        <p>
                            BiblioApp is an app that helps users get book
                            recommendations based on their choice, stay up to
                            date with the happenings in the literary world,
                            about books, authors and events.
                            {/* This is a concept app which I undertook as a project while I was learning all about designing experiences.*/}
                        </p>
                        <p>
                            This was the first project I undertook while donning
                            the hat of an user experience designer. This was
                            built purely for learning purposes so I kept the
                            process simple and precise.
                        </p>
                        <div className="single-project-media">
                            <img src={process} alt="biblioapp process"></img>
                        </div>
                        <p>Let's start with the process.</p>
                        <h2>1. Understanding</h2>
                        <div className="content-block">
                            <h3>Ideation</h3>
                            <p>
                                <ul>
                                    <li>
                                        &mdash; Nurture reading habits amongst
                                        new users by recommending books based on
                                        their likes.
                                    </li>
                                    <li>
                                        &mdash; Connect readers together through
                                        book clubs.
                                    </li>
                                    <li>
                                        &mdash; Stay up-to-date with the
                                        happenings in the literary world, from
                                        book launches to events and news.
                                    </li>
                                    <li>
                                        &mdash; Adding the aspect of
                                        gamification through literary quizzes
                                        where users can challenge each other to
                                        show off their knowledge and earn
                                        “experience points”.
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="content-block">
                            <h3>User Personas</h3>
                            <p>
                                The first step was to better understand the user
                                needs. I set out to research further on the
                                needs and pain points. The research group
                                consisted of people of age group - 20 to 40;
                                from various professions (students, teachers,
                                artists, IT professionals). After this phase,
                                the user personas were drawn out.
                            </p>
                        </div>
                        <img src={personas} alt="biblioapp personas"></img>
                        <h2>2. Planning/Analysis</h2>
                        <div className="content-block">
                            <h3>Sitemap:</h3>
                            <p>
                                Before starting sketching and then moving on to
                                wireframes, the app’s sitemap was laid out for
                                more clarity.
                            </p>
                        </div>
                        <img src={sitemap} alt="biblioapp sitemap"></img>
                        <div className="content-block">
                            <h4>Sketching:</h4>
                            <p className="mb-0">
                                Now let the sketching commence!{" "}
                                <span
                                    role="img"
                                    aria-label="smiling face emoji"
                                >
                                    😄
                                </span>
                            </p>
                        </div>
                        <img src={bibSketch} alt="biblioapp sketches"></img>
                        <p className="mb-0">
                            Here is a snapshot of the wireframes.
                        </p>
                        <img
                            src={bibWireframe}
                            alt="biblioapp wireframes"
                        ></img>

                        <p>
                            After the first set of wireframes were created, I
                            quickly created a click-through prototype using
                            Adobe XD.
                        </p>
                        <div className="single-project-media">
                            <iframe
                                src="https://player.vimeo.com/video/268436568"
                                width="640"
                                height="480"
                                frameBorder="0"
                                title="biblioapp user journey video"
                            ></iframe>
                        </div>
                        <p>
                            I incorporated user testing further throughout the
                            process right after the minimum viable working
                            prototype was ready. The feedback was invaluable and
                            I could immediately understand the importance of
                            user testing early on.
                        </p>
                        <h2>3. Designing</h2>
                        <p>
                            The wireframes were further improved based on the
                            feedback received after user testing. I went forward
                            with the android system font "Roboto". The primary
                            color chosen was :{" "}
                            <span className="bib-color-block">#450AE4</span>
                            <br />
                            Here is an overview of all that is there in the app
                            &mdash;
                        </p>
                        <div className="content-block">
                            <p>
                                Tab Bar consists of 4 options : Home, Explore,
                                Community, Challenge.
                            </p>
                            <p>
                                <b>Home Screen : </b>Consists of featured lists,
                                recommendations based on the choices selected
                                previously, collections, latest news, an
                                overview of events. It a rundown of{" "}
                            </p>
                            <p>
                                <b>Explore : </b> Consists of 3 tabs namely
                                "Books", "Authors", "Events". <b>Books</b> have
                                all the latest collections of books.{" "}
                                <b>Authors</b> have news and interviews all
                                related to authors. <b>Events</b> have all the
                                rundown of events nearby and around the world.
                            </p>
                            <p>
                                <b>Community : </b> Consists of 2 tabs namely
                                "Friends" and "Bookclub". Through <b>Friends</b>{" "}
                                one can view all the updates from the people
                                they are following and also find new friends to
                                follow. <b>Bookclub </b> has everything related
                                to book clubs, the ones you follow and it also
                                gives new suggestions.
                            </p>
                            <p>
                                <b>Challenge : </b> Consists of 2 tabs namely
                                "Challenge" and "Leaderboard". In{" "}
                                <b>Challenge</b> you can view all the people who
                                have challenged you in a quiz, you can view the
                                results of past challenges and of course you can
                                challenge your friends as well.{" "}
                                <b>Leaderboard</b> shows the leaderboard scores
                                amongst the people you follow.
                            </p>
                            <p>
                                App bar consists of options to "Search", access
                                "Notifications" and view or edit your own
                                "Profile". <b>Profile</b> showcases all your
                                upcoming events, your reviews, collections etc.{" "}
                            </p>
                        </div>

                        <div className="content-block">
                            <h3>Prototype</h3>
                            <p>
                                Here is the final interactive prototype, that
                                will give you an in-depth look into the screens
                                and the flow.
                            </p>
                            <div
                                className="prototype"
                                style={{
                                    margin: "60px auto",
                                    maxWidth: "100%",
                                    textAlign: "center",
                                }}
                            >
                                <iframe
                                    src="https://marvelapp.com/3f6ed2g?emb=1&iosapp=true&frameless=false"
                                    width="390"
                                    height="755"
                                    allowtransparency="true"
                                    frameBorder="0"
                                    title="bibliopapp prototype"
                                ></iframe>
                            </div>
                        </div>
                        <div className="content-block">
                            <h3>Scope of Improvement:</h3>
                            <p>
                                This is not perfect and there is always some
                                scope of improvement. I would like to draw out
                                intermediate states, empty states and some maybe
                                corner cases which I might have skipped out
                                during the process. Visual design not being my
                                strong point, I know the visuals can be improved
                                quite a lot from the current state{" "}
                                <span
                                    role="img"
                                    aria-label="smiling face emoji"
                                >
                                    😊
                                </span>{" "}
                                If you, the one reading this, have any
                                suggestions or feedback feel free to{" "}
                                <a
                                    href="mailto:ananyaneogi11@gmail.com"
                                    className="link"
                                >
                                    get in touch.
                                </a>
                            </p>
                        </div>
                        <p>
                            Starting from a blank paper to a prototype, it was a
                            great learning experience and I am sure I am
                            carrying forward tonnes of lessons which will help
                            me create even better experiences in the future.
                        </p>
                    </div>
                    <Link to="/work/enchanting-travels" className="read-btn">
                        Next: Enchanting Travels &nbsp; &#187;
                    </Link>
                </div>
            </div>
        </Layout>
    )
}
