import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                        description
                        menuLinks {
                            name
                            link
                        }
                    }
                }
            }
        `}
        render={data => (
            <div className="container">
                <Helmet
                    title={data.site.siteMetadata.title}
                    meta={[
                        {
                            name: "description",
                            content: data.site.siteMetadata.description,
                        },
                        {
                            name: "keywords",
                            content:
                                "frontend developer, ui ux developer, frontend blog, tech blog",
                        },
                    ]}
                />
                <Header
                    menuLinks={data.site.siteMetadata.menuLinks}
                    siteTitle={data.site.siteMetadata.title}
                />
                <main id="main-content">{children}</main>
                <Footer />
            </div>
        )}
    />
)

export default Layout
