import React, { Component } from "react"

import HeaderImage from "../assets/header-image1.png"

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="footer-wrapper">
                    <div className="footer-links">
                        <ul>
                            <li>
                                <a
                                    href="https://github.com/ananyaneogi"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Github
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://dev.to/ananyaneogi"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Dev
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/_ananyaneogi"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://codepen.io/ananyaneogi"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Codepen
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://ananyaneogi.com/rss.xml"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    RSS Feed
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="footer-image" style={{ width: "300px" }}>
                        <img src={HeaderImage} alt="" />
                    </div> */}
                </div>
                <small style={{ color: "var(--light-gray)" }}>
                    &copy; {new Date().getFullYear()} Copyright Ananya Neogi.
                </small>
            </footer>
        )
    }
}

export default Footer
