import React from "react"
import { Link } from "gatsby"
// import ToggleSwitch from "./ToggleSwitch"

const Header = ({ menuLinks }) => (
    <nav className="topnav">
        <a href="#main-content" className="skip-link">
            Skip to main content
        </a>
        <Link to="/" className="nav-name" title="AnanyaNeogi.com">
            <div className="logo">
                <svg
                    viewBox="0 0 45 33"
                    xmlns="http://www.w3.org/2000/svg"
                    className="a-mark"
                >
                    <path
                        d="M13.4375,1 C17.1875187,1 20.0520734,1.79686703 22.03125,3.390625 C24.0104266,4.98438297 25,7.20831906 25,10.0625 L25,24.34375 C25,25.4479222 25.4479122,26 26.34375,26 C26.7187519,26 27.2447883,25.8385433 27.921875,25.515625 C28.5989617,25.1927067 29.1666644,24.8229187 29.625,24.40625 L29.625,28.84375 C26.9374866,30.4895916 24.4791778,31.3125 22.25,31.3125 C20.8749931,31.3125 19.817712,31.0833356 19.078125,30.625 C18.338538,30.1666644 17.8229181,29.3541725 17.53125,28.1875 C14.5937353,30.2708437 11.6458481,31.3125 8.6875,31.3125 C6.58332281,31.3125 4.77604922,30.6041738 3.265625,29.1875 C1.75520078,27.7708262 1,26.0729266 1,24.09375 C1,21.5729041 1.7499925,19.5833406 3.25,18.125 C4.7500075,16.6666594 7.37498125,15.3958387 11.125,14.3125 L17.34375,12.5 L17.34375,11.15625 C17.34375,8.30206906 15.6146006,6.875 12.15625,6.875 C10.3854078,6.875 8.62500875,7.28124594 6.875,8.09375 C5.12499125,8.90625406 3.53125719,10.1041587 2.09375,11.6875 L2.09375,4.78125 C5.28126594,2.26040406 9.06247812,1 13.4375,1 Z M8.75,22.9375 C8.75,24.0000053 9.08853828,24.8541634 9.765625,25.5 C10.4427117,26.1458366 11.281245,26.46875 12.28125,26.46875 C13.9687584,26.46875 15.6562416,25.6979244 17.34375,24.15625 L17.34375,16.46875 C15.1979059,17.1979203 13.5156311,17.8854134 12.296875,18.53125 C11.0781189,19.1770866 10.1822945,19.83333 9.609375,20.5 C9.03645547,21.16667 8.75,21.9791619 8.75,22.9375 Z M38.875,21.71875 C40.1458397,21.71875 41.2656202,22.1874953 42.234375,23.125 C43.2031298,24.0625047 43.6875,25.19791 43.6875,26.53125 C43.6875,27.8229231 43.2187547,28.9427036 42.28125,29.890625 C41.3437453,30.8385464 40.20834,31.3125 38.875,31.3125 C37.5624934,31.3125 36.4322964,30.8385464 35.484375,29.890625 C34.5364536,28.9427036 34.0625,27.8229231 34.0625,26.53125 C34.0625,25.19791 34.5416619,24.0625047 35.5,23.125 C36.4583381,22.1874953 37.5833269,21.71875 38.875,21.71875 Z"
                        strokeWidth="2"
                        fill="none"
                    />
                </svg>
            </div>
        </Link>
        <ul style={{ display: "flex", flex: 1 }}>
            {menuLinks.map(link => (
                <li key={link.name}>
                    <Link to={link.link} activeClassName="selected">
                        {link.name}
                    </Link>
                </li>
            ))}
            {/* <li className="theme-switch">
                <ToggleSwitch />
            </li> */}
        </ul>
    </nav>
)

export default Header
